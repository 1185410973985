import React, {Component} from 'react';
// Custom module import 
import { 
		objectValuesEmpty, 
		flash 
	} from './../helpers.js'

class CPAddDegree extends Component
{
	constructor(props){
		super(props);
		this.state = {
			degreename:'',
			description:'',
			errors: {
		        degreename: '',
		        description: '',
		    },
		    isSubmitting: false,
		}
	}

	// Handle registration form
	CPSaveDegree = event => {
		event.preventDefault();
		
		// Verify for errors again
		// And Display errors under the empty fields
    	let errors = this.state.errors;
    	let state = Object.assign({}, this.state);
    	Object.entries(state).map((field) => {
    		let toValidate = ['name', 'description', 'status'];
    		if(toValidate.includes(field[0])){
    			errors.[field[0]] = state.[field[0]] == '' || state.[field[0]] == undefined || state.[field[0]] == null
	            ? `${ field[0].charAt(0).toUpperCase()+field[0].slice(1) } is Required`
	            : '';
    		}
    	});

		if( objectValuesEmpty(this.state.errors) ) {
			// disable submit button
			this.setState({ isSubmitting : true })

			// Send form data
			const data = {
				name: this.state.degreename, 
				description: this.state.description, 
				status: this.state.status
			};
			this.props.addDegree(data);
	    }else{
	    	// reactivate button
	    	this.setState({isSubmitting: false});

	    	// Show message
	    	const theme = 'panel bordered primary rounded';
	    	const icon =`fa fa-times-circle`;
	    	const message ='Kindly fix the following errors';
  			flash(theme, icon, message, '#alert');
	    }
	}

	// Handle input field change
	handleFieldChange = (event) => {
		event.preventDefault();

		// Validate form
		const { name, value } = event.target;
		
		// Fill form field
		this.setState( { [name] : value } );
		// Check for errors
		// TODO: do more validation
		let errors = this.state.errors;
		switch(name){
			case 'degreename':
				errors.degreename = 
	          	value == '' || value == undefined
	            ? 'Degree Name Required'
	            : '';
	        break;

	        case 'description':
				errors.description = 
	          	value == '' || value == undefined
	            ? 'Description is Required'
	            : '';
	        break;
		}
		this.setState({errors, [name]: value});
	}

	render(){
		let errors = this.state.errors;

		return (
			<React.Fragment>
				<div id="alert" className="hide">
					<span className="icon"><i className="" id="icon"></i></span>
					<span className="message" id="message"></span>
				</div>
				<form id="customerForm" className="grouped row spread bottom" onSubmit={ this.CPSaveDegree }>
					<div>
						<div className="helper">
						    <label htmlFor="degreename">Degree Name</label>
						    <input value={ this.state.degreename } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="degreename" name="degreename" placeholder="Degree Name" />
						    { errors.degreename &&
							    <i className="text">
							    	<i className="fa fa-question-circle"></i> 
							    	<span className="text-tone-primary">{errors.degreename}</span>
							    </i>
							}
						</div>
						<div className="helper">
						   	<label htmlFor="lastname">Description</label>
						   	<input value={ this.state.description } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="description" name="description" placeholder="Description" />
						   	{ errors.description &&
							   	<i className="text">
							   		<i className="fa fa-question-circle"></i> 
							    	<span className="text-tone-primary">{errors.description}</span>
							    </i>
							   	}
						</div>
					   	<div className="row grouped middle">
						    <div className="helper">
						    	<label htmlFor="status">Status</label>
							   	<div className="row">
							   	 	<label className="radio">
										<div className="inputs">
											<input type="radio" name="admin_status" checked={ !this.state.status }  onChange={ (e)=>this.setState({status: false}) }/>
											<span className="dot"></span>
										</div>
										<span className="label">Disabled</span>
									</label>
									<label className="radio">
										<div className="inputs">
											<input type="radio" name="admin_status" checked={ this.state.status } onChange={ (e)=>this.setState({status: true}) }/>
											<span className="dot"></span>
										</div>
										<span className="label">Enabled</span>
									</label>
							   </div>
							 </div>
						</div>
					</div>
					<aside className="text center margin">
						<button className={ `rounded margin top-two ${ this.state.isSubmitting  ? 'bordered disabled' : 'fill-primary' }` } disabled={ this.state.isSubmitting }>Save</button>
					</aside>
				</form>
			</React.Fragment>
		);
	}
}

export default CPAddDegree;