import React, { Component } from 'react';

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke, progress } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress, stroke_color, minutes, seconds } = this.props;
    const strokeDashoffset = -(this.circumference - progress / 100 * this.circumference);

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
        className="chartpie"
        >
        <circle
          stroke={ stroke_color }
          fill="transparent"
          strokeWidth={ stroke }
          strokeDasharray={ this.circumference + ' ' + this.circumference }
          style={ { strokeDashoffset } }
          strokeWidth={ stroke }
          r={ this.normalizedRadius }
          cx={ radius }
          cy={ radius }
          />
          <text x="50%" y="50%" textAnchor="middle" stroke={ stroke_color } fontSize="1.3rem" dy=".3em">{ minutes }:{ seconds }</text>
      </svg>
    );
  }

}

export default ProgressRing;
