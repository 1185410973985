// Register service worker
// can check if we are on production before acquiring
require('../scripts/service-worker-register.js');

  // DOM manipulation element
import { 
getElement,
getAllElements,
toggleBodyScroll,
toggleable,
addClass,
removeClass,
hasClass 
} from './js/helpers.js'
import './js/stickyjs.js';

// REACT
import React from 'react';
import ReactDOM from 'react-dom';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   Redirect
// } from "react-router-dom";

import Modal from './js/Components/Modal';
import CSEExam from './js/Components/CSEExam';
import VirtualInterview from './js/Components/VirtualInterview';


// Style files entry-point
import './style/fixmaster.scss';


// Log sample settings
// console.log("Sum of 4 and 7 is " + add(4, 7));
// console.log("Active API endpoint: " + API_DOMAIN);
// console.log("APP version: " + APP_VERSION);

// All set
// console.log('Fixmaster Started...');

// Bring Master Component
// require( './Fixmaster.jsx');

// ******* CUSTOM JS ******** //


// Main Site Menu
let show_menu_button = getElement('#show-mobile-menu');
let mobile_menues = Array.from(getAllElements('.desktop'));

if( show_menu_button ){
	show_menu_button.addEventListener('click', e => {
		e.preventDefault();
		mobile_menues.map( m => toggleable(m, 'show'));
	})
}

// Global Notice
const global_notice = getElement('.global_notice');
const global_notice_close = getElement('.global_notice_close');

if(global_notice_close && global_notice){
	global_notice_close.addEventListener('click', e => {
		toggleable(global_notice, 'hide');
		toggleable(global_notice, 'global_notice');
		removeClass(global_notice, 'show');
	})
}


// Main Site Signin
const homeSigninButton = getElement("#homeSigninButton");
let modalSlot = getElement("#modal");

if( homeSigninButton ){
	homeSigninButton.addEventListener("click", e => {
		e.preventDefault();
		document.querySelector('body').classList.add('lock');
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `primary` }
						theme_header_title = { `Sign In` }
						theme_header_icon = { `fa fa-plus-circle` }
						content = { `signin` }
						width = { `500` }
						/>, modalSlot);
	});
}


// Main Site Careers Apply
let careers_apply_btns = Array.from(getAllElements('.careers_apply'));
careers_apply_btns.forEach( apply => {
	if( apply != undefined ){
		apply.addEventListener('click', (e) => {
			e.preventDefault();
			document.querySelector('body').classList.add('lock');
			ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { ` ${ apply.dataset.type.charAt(0).toUpperCase() +  apply.dataset.type.slice(1) } Sign Up` }
						theme_header_icon = { `fa fa-align-right` }
						theme_footer = { `bordered` }
						content = { `CustomerRegistrationForm` }
						userType = { apply.dataset.type }
						width = { `800` }
						/>, modalSlot);
		})
	}
})

// Main Site get started
let get_started_btns = Array.from(getAllElements('.get_started'));
get_started_btns.forEach( start => {
	if( start ){
		start.addEventListener('click', (e) => {
			e.preventDefault();
			document.querySelector('body').classList.add('lock');
			ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Customer Sign Up` }
						theme_header_icon = { `` }
						content = { `CustomerRegistrationForm` }
						userType = { `` }
						theme_footer = { `bordered` }
						width = { `800` }
						/>, modalSlot);
		})
	}
})

// CSE Onboarding Exam
const exam_area = getElement("#cse_exam");
if(exam_area){
	ReactDOM.render(<CSEExam />, exam_area);
}
// Virtual Interview
const VI_area = getElement("#cse_vi");
if(VI_area){
	ReactDOM.render(<VirtualInterview />, VI_area);
}

// Cpanel add new application
const cp_add_new_application_btn = getElement('#cp_add_new_application');
if(cp_add_new_application_btn && modalSlot){
	cp_add_new_application_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Application` }
						theme_header_icon = { `fa fa-map` }
						content = { `newapplication` }
						/>, modalSlot);
	})
}

// Cpanel add new area
const cp_add_new_area_btn = getElement('#cp_add_new_area');
if(cp_add_new_area_btn && modalSlot){
	cp_add_new_area_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Area` }
						theme_header_icon = { `fa fa-map` }
						content = { `newarea` }
						/>, modalSlot);
	})
}

// Cpanel add new bank
const cp_add_new_bank_btn = getElement('#cp_add_new_bank');
if(cp_add_new_bank_btn && modalSlot){
	cp_add_new_bank_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Bank` }
						theme_header_icon = { `fa fa-house` }
						content = { `newbank` }
						/>, modalSlot);
	})
}

// Cpanel add new country
const cp_add_new_country_btn = getElement('#cp_add_new_country');
if(cp_add_new_country_btn && modalSlot){
	cp_add_new_country_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { ` Add Country` }
						theme_header_icon = { `fa fa-map` }
						content = { `newcountry` }
						/>, modalSlot);
	})
}

// Cpanel add cse
const cp_add_new_cse_btn = getElement('#cp_add_new_cse');
if(cp_add_new_cse_btn && modalSlot){
	cp_add_new_cse_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add CSE` }
						theme_header_icon = { `fa fa-user-plus` }
						content = { `CustomerRegistrationForm` }
						/>, modalSlot);
	})
}

// Cpanel add franchisee
const cp_add_new_franchisee_btn = getElement('#cp_add_new_franchisee');
if(cp_add_new_franchisee_btn && modalSlot){
	cp_add_new_franchisee_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Franchisee` }
						theme_header_icon = { `fa fa-user-plus` }
						content = { `franchisee` }
						/>, modalSlot);
	})
}

// Cpanel add new degree
const cp_add_new_degree_btn = getElement('#cp_add_new_degree');
if(cp_add_new_degree_btn && modalSlot){
	cp_add_new_degree_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Degree` }
						theme_header_icon = { `fa fa-certificate` }
						content = { `newdegree` }
						/>, modalSlot);
	})
}

// Cpanel add new gender
const cp_add_new_gender_btn = getElement('#cp_add_new_gender');
if(cp_add_new_gender_btn && modalSlot){
	cp_add_new_gender_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Gender` }
						theme_header_icon = { `fa fa-snowflake-o` }
						content = { `newgender` }
						/>, modalSlot);
	})
}

// Cpanel add new language
const cp_add_new_language_btn = getElement('#cp_add_new_language');
if(cp_add_new_language_btn && modalSlot){
	cp_add_new_language_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Language` }
						theme_header_icon = { `fa fa-sign-language` }
						content = { `newlanguage` }
						/>, modalSlot);
	})
}

// Cpanel add new lga
const cp_add_new_lga_btn = getElement('#cp_add_new_lga');
if(cp_add_new_lga_btn && modalSlot){
	cp_add_new_lga_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add LGA` }
						theme_header_icon = { `fa fa-map` }
						content = { `newlga` }
						/>, modalSlot);
	})
}

// Cpanel add new marital status
const cp_add_new_marital_status_btn = getElement('#cp_add_new_marital_status');
if(cp_add_new_marital_status_btn && modalSlot){
	cp_add_new_marital_status_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Marital Status` }
						theme_header_icon = { `fa fa-cog` }
						content = { `newmaritalstatus` }
						/>, modalSlot);
	})
}

// Cpanel add new religion
const cp_add_new_religion_btn = getElement('#cp_add_new_religion');
if(cp_add_new_religion_btn && modalSlot){
	cp_add_new_religion_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Religion` }
						theme_header_icon = { `fa fa-map` }
						content = { `newreligion` }
						/>, modalSlot);
	})
}

// Cpanel add new state
const cp_add_new_state_btn = getElement('#cp_add_new_state');
if(cp_add_new_state_btn && modalSlot){
	cp_add_new_state_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add State` }
						theme_header_icon = { `fa fa-map` }
						content = { `newstate` }
						/>, modalSlot);
	})
}

// Cpanel add new territory
const cp_add_new_territory_btn = getElement('#cp_add_new_territory');
if(cp_add_new_territory_btn && modalSlot){
	cp_add_new_territory_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Territory` }
						theme_header_icon = { `fa fa-map` }
						content = { `newterritory` }
						/>, modalSlot);
	})
}

// Cpanel add new town
const cp_add_new_town_btn = getElement('#cp_add_new_town');
if(cp_add_new_town_btn && modalSlot){
	cp_add_new_town_btn.addEventListener('click', e => {
		e.preventDefault();
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `rounded` }
						theme_header_title = { `Add Town` }
						theme_header_icon = { `fa fa-map` }
						content = { `newtown` }
						/>, modalSlot);
	})
}

// Cpanel add user
const cp_add_new_user_btn = getElement('#cp_add_new_user');

if(cp_add_new_user_btn && modalSlot){
	cp_add_new_user_btn.addEventListener('click', e => {
		e.preventDefault();
		document.querySelector('body').classList.add('lock');
		ReactDOM.render(<Modal 
						location = { modalSlot } 
						theme = { `rounded fill-plain` }
						theme_header = { `fill-primary rounded-tops` }
						theme_header_title = { `Add account` }
						theme_header_icon = { `fa fa-plus` }
						content = { `` }
						/>, modalSlot);
	})
}
