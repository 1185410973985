import React, { Component } from 'react';
import InterviewQuestionaire from './InterviewQuestionaire';
import ProgressRing from './ProgressRing';
import Spinner from './Spinner';
import axios from 'axios';

class VirtualInterview extends Component {

	constructor(props){
		super(props);
		this.state =  {
			progress: 100,
			minutes: '00',
			seconds: '00',
			duration_secs:0,
			duration: 0,
			strokeColor: 'green',
			instructed: false,
			quiz: null,
			questions: [],
			q_cursor:0,
			nonext: false,
			noprev: true,
			quiz_answers: {},
			end_interview: false,
			started_interview: false,
			tick: 1000,
			t_todo: 0,
			launching: false,
		}

		let request_configs = {
			headers: {
				'Content-Type': 'application/json',
			   Authorization: 'Bearer ' + API_KEY, //the token is a variable which holds the token
			 }
		}
	}


	componentDidMount(){
		let duration = 30;
		// Get CSE quiz data
		axios.get(API_DOMAIN + '/onboarding/quiz/3', this.request_configs)
		.then((res) => {
			if(res.data.status){
				let data = res.data.data;
				this.setState({ quiz: data });
				this.setState({ duration: data.duration });

				let time_to_do = data.duration * 60 ;
				this.setState({ t_todo: time_to_do });

				// Get quiz questions
				axios.get(API_DOMAIN + '/onboarding/quiz/'+data.id+'/questions', this.request_configs)
				.then((res) => {
					if(res.data.status){
						let data = res.data.data;
						this.setState({ questions: data });
					}else{
						console.log('error')
					}
				})
				.catch((err)=>{
					console.log(err)
				});
			}else{
				// console.log('error')
			}
		})
		.catch((err)=>{
			// console.log(err)
		});
	}


	nextQuestion = () => {
		if( this.state.questions.length - 1 > this.state.q_cursor )
			this.setState({ q_cursor: this.state.q_cursor + 1 });

		if( this.state.questions.length - 2 == this.state.q_cursor ){
			this.setState({ nonext: true });
		}else{
			this.setState({ noprev: false });
		}	
	}


	// initEnd = () => {
	// 	this.setState({ end_interview: true,  tick: 1000000});

	// 	// This is crasy!!
	// 	// React requires a delay to update state
	// 	setTimeout( () => {
	// 		this.endInterview();
	// 	}, 5000)
	// }

	endInterview = () => {
		// Stop video recording
		let preview = document.getElementById("preview");
		this.stop( preview.srcObject );

		// End exam
    	this.setState({ end_interview: true,  tick: 1000000});

    	// Submit form
    	// this.answer(this.state.quiz_answers);

    	// kill clock speed
    	this.setState({ duration_secs: 0, seconds: 0,  progress: 100 });
    	this.startTimer(this.state.tick);
	}

	startTimer = (duration) => {
	    var timer = duration, minutes, seconds;

	    setInterval( () => {
	        let minutes = parseInt(timer / 60, 10);
	        let seconds = parseInt(timer % 60, 10);

	        let min = minutes < 10 ? "0" + minutes : minutes;
	        let sec = seconds < 10 ? "0" + seconds : seconds;

	        if (--timer < 0) {
	            timer = duration;
	        }

	        let total_duration_seconds = this.state.duration * 60;

	        if(this.state.duration_secs > (total_duration_seconds / 2) )
	        	this.setState({ strokeColor: 'lightgreen'});

	        if(this.state.duration_secs >= (total_duration_seconds - 10) )
	        	this.setState({ strokeColor: 'tomato'});

	        // percentage progress
			let a_slice = 100 / total_duration_seconds

	        this.setState({ minutes: min, seconds: sec});
	        this.setState({ duration_secs: this.state.duration_secs + 1 });
	        this.setState({ progress: this.state.progress - a_slice});

	        if( this.state.duration_secs >= total_duration_seconds && !this.state.end_interview ){
	        	// End the exam automatically
	        	this.endInterview();	        	
	        }

	    }, this.state.tick);
	}
	wait = (delayInMS) => {
	  return new Promise(resolve => setTimeout(resolve, delayInMS));
	}

	startInterview = (duration, e) => {
	
		// Handle video recording
		let preview = document.getElementById("preview");
		let downloadButton = document.getElementById("downloadButton");
		let recordingTimeMS = duration * 60 * 60;
		let channels = { video: true, audio: true }


		// Start spinner
		this.setState({ launching: true })

		navigator.mediaDevices.getUserMedia(channels).then(stream => {
		    preview.srcObject = stream;
		    // downloadButton.href = stream;
		    preview.captureStream = preview.captureStream || preview.mozCaptureStream;
		    
		    // start interview interactions
	    	this.setState({ started_interview: true });

	    	// Start timer 
  			this.startTimer(duration);

  			// Stop spinner
  			this.setState({ launching: false })

		    return new Promise(resolve => preview.onplaying = resolve);
	  	}).then(() => this.startRecording(preview.captureStream(), recordingTimeMS))
		  	.then (recordedChunks => {
		    let recordedBlob = new Blob(recordedChunks, { type: "video/webm" });
		    this.storeVideo(recordedBlob)
		    console.log('what')
		    recording.src = URL.createObjectURL(recordedBlob);
		    downloadButton.href = recording.src;
		    downloadButton.download = "RecordedVideo.webm";
	  	})
	  	.catch();
	}

	storeVideo = (vid) => {
		console.log(vid)
		// signal backend
		axios.post(API_DOMAIN + '/onboarding/store/'+this.state.quiz.id, { start: true, stream: vid }, this.request_configs)
		.then((res) => {
			console.log(res.data)
		})
		.catch((err)=>{
			console.log(err)
		});
	}

	// Functions from mozilla
	// Stop recording
	stop = stream => {
	  stream.getTracks().forEach(track => track.stop());
	}

	// Start recording
	startRecording = (stream, lengthInMS) => {
	  let recorder = new MediaRecorder(stream);
	  let data = [];

	  recorder.ondataavailable = event => data.push(event.data);
	  recorder.start();
	  console.log(recorder.state + " for " + (lengthInMS/1000) + " seconds...");

	  let stopped = new Promise((resolve, reject) => {
	    recorder.onstop = resolve;
	    recorder.onerror = event => reject(event.name);
	  });

	  let recorded =this.wait(lengthInMS).then(
	    () => recorder.state == "recording" && recorder.stop()
	  );

	  return Promise.all([
	    stopped,
	    recorded
	  ])
	  .then(() => data);
	}

	// END Mozilla goodies

	render() {
		const w = {
			width: '100%'
		}
		const bg_video = {
			backgroundImage: "url('./assets/images/interview.png')",
    		backgroundSize: 'cover',
		}
		return (
			this.state.instructed 
			?
				!this.state.end_interview ?
				<React.Fragment>
					{ this.state.launching  && <Spinner /> }
					<div className="video-space">
						<video id="preview" className="video" width="100%" height="auto" style = { bg_video } autoPlay muted></video> 
						<div className="grouped center video-controls">
							
							{ !this.state.started_interview 
							? <button className="start-video start" onClick = { (e) => this.startInterview(this.state.t_todo, e) }>Start</button>
							: <button id="downloadButton" className="start-video end" onClick = { () => this.endInterview() } >Done</button> }
						</div>
					</div>
					{ 
						this.state.started_interview && 
						<div className="grouped spread middle margin top-two">
				          <div className="grouped spread bottom margin top-two dialog-left" style = { w }>
				              <div className="grouped spread left middle msg-box">
				                <div className="msg">
				                  <h5>Fixmaster Bot (Host)</h5>
				                  <i className="fa fa-comment-o"></i>
				                  <blockquote className="panel pill rounded">
				                    { this.state.questions[this.state.q_cursor].question_text }
				                  </blockquote>
				                </div>
				              </div>
				              <div className="dialog-right">
				                <ProgressRing 
				                  radius = { `30`}  
				                  stroke = { '0' }  
				                  progress = { this.state.progress }
				                  stroke_color = { this.state.strokeColor }
				                  minutes = { this.state.minutes }
				                  seconds = { this.state.seconds }
				                  />
				                <button onClick={ this.nextQuestion } className={  this.state.nonext ? 'disabled' : '' }>Next question <i className="fa fa-arrow-right "></i></button>
				              </div>
				            </div>
				        </div>
					}	
					<br/>
				</React.Fragment>
				: 
				<React.Fragment>
					<h2>Interview has ended</h2>
					<p>Thank you for submitting your VI. Our team is already reviewing it. Kindly check back in 24h. <br/> <b>Good luck!</b></p>
				</React.Fragment>
			: 
			<React.Fragment>
				<h2>Virtual Interview Instructions</h2>
				<p>Once you start do not close this tab or your browser. Stopping at any point will be considered as your final grade... </p>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eleifend ornare et urna integer molestie odio. Mi molestie in mi augue. Quis amet morbi dignissim proin tortor hendrerit. Risus sem nibh nulla dui. Suspendisse imperdiet elit non, diam sagittis, et diam. 
					Tristique vel tortor commodo facilisi. Aliquam feugiat maecenas tellus volutpat lacus, libero amet. Consectetur sit diam et molestie vitae mi ut. Accumsan, tellus enim adipiscing aliquam odio accumsan, bibendum imperdiet justo. Nulla augue mattis quis massa vel. Arcu gravida et dignissim egestas ultrices non sit id et. Vulputate lacinia dui, a viverra senectus mauris enim ut lacus. Quis libero congue rhoncus mi fermentum ut facilisis. Non potenti sem dui nulla morbi id malesuada nunc. Orci integer pulvinar nunc, phasellus.
				</p>
				<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos quisquam laudantium sequi possimus tempore, perferendis adipisci eum libero inventore sapiente minus ipsum quod dolorum, qui obcaecati non labore autem harum?</p>
				<hr/>
				<p>
					<button className="fill-primary rounded" onClick={ () => this.setState({ instructed: true }) }>Start Interview</button>
				</p>
			</React.Fragment>
		);
	}
}

export default VirtualInterview;