import React, {Component} from 'react';
// Custom module import 
import { 
		validEmailRegex , 
		objectValuesEmpty, 
		flash 
	} from './../helpers.js'

class CPAddArea extends Component{

	constructor(props){
		super(props);
		this.state = {
			iso:'',
			name:'',
			nicename:'',
			iso3:'',
			numcode:'',
			phonecode:'',
			status:false,
			errors: {
		        iso: '',
		        name: '',
		        nicename: '',
		        iso3: '',
		        numcode: '',
				phonecode: '',
		    },
		    isSubmitting: false,
		}
	}

	// Handle registration form
	CPSaveArea = event => {
		event.preventDefault();
		// Verify for errors again
		// And Display errors under the empty fields
    	let errors = this.state.errors;
    	let state = Object.assign({}, this.state);
    	Object.entries(state).map((field) => {
    		let toValidate = ['iso', 'name', 'nicename', 'iso3', 'numcode', 'phonecode', 'status'];
    		if(toValidate.includes(field[0])){
    			errors.[field[0]] = state.[field[0]] == '' || state.[field[0]] == undefined || state.[field[0]] == null
	            ? `${ field[0].charAt(0).toUpperCase()+field[0].slice(1) } is Required`
	            : '';
    		}
    	});

		if( objectValuesEmpty(this.state.errors) ) {
			// disable submit button
			this.setState({ isSubmitting : true })

			// Send form data
			this.props.addArea({
				iso: this.state.iso, 
				name: this.state.name, 
				nicename: this.state.nicename, 
				iso3: this.state.iso3,
				numcode: this.state.numcode, 
				phonecode: this.state.phonecode, 
				status: this.state.status
			});
	    }else{
	    	// reactivate button
	    	this.setState({isSubmitting: false});

	    	// Show message
	    	const theme = 'panel bordered primary rounded';
	    	const icon =`fa fa-times-circle`;
	    	const message ='Kindly fix the following errors';
  			flash(theme, icon, message, '#alert');
	    }
	}

	// Handle input field change
	handleFieldChange = (event) => {
		event.preventDefault();

		// Get input name and value
		const { name, value } = event.target;
		
		// Fill form field
		this.setState( { [name] : value } );

		// Check for errors
		// TODO: do more validation
		let errors = this.state.errors;
		switch(name){
			case 'iso':
				errors.iso = 
	          	value == '' || value == undefined
	            ? 'ISO Required'
	            : '';
	        break;

			case 'name':
				errors.name = 
	          	value == '' || value == undefined
	            ? 'Name Required'
	            : '';
	        break;

			case 'nicename':
				errors.nicename = 
	          	value == '' || value == undefined
	            ? 'Nice Name Required'
	            : '';
	        break;

	        case 'iso3':
				errors.iso3 = 
	          	value == '' || value == undefined
	            ? 'ISO 3 Required'
	            : '';
	        break;

	        case 'numcode':
				errors.numcode = 
	          	value == '' || value == undefined
	            ? 'Number Code Required'
	            : '';
	        break;

	        case 'phonecode':
				errors.phonecode = 
	          	value == '' || value == undefined
	            ? 'Phone Code Required'
	            : '';
	        break;
		}
		this.setState({errors, [name]: value});
	}

	render(){
		let errors = this.state.errors;

		return (
			<React.Fragment>
				<div id="alert" className="hide">
					<span className="icon"><i className="" id="icon"></i></span>
					<span className="message" id="message"></span>
				</div>
				<form id="customerForm" className="grouped row spread bottom" onSubmit={ this.CPSaveArea}>
					<div>
						<div className="row">
						    <div className="helper">
						    	<label htmlFor="iso">ISO</label>
						    	<input value={ this.state.iso } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="iso" name="iso" placeholder="ISO" />
						    	{ errors.iso &&
							    	<i className="text">
							    		<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.iso}</span>
							    	</i>
							    }
						    </div>
						    <div className="helper">
						    	<label htmlFor="iso3">ISO 3</label>
						    	<input value={ this.state.iso3 } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="iso3" name="iso3" placeholder="ISO 3" />
						    	{ errors.iso3 &&
							    	<i className="text">
							    		<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.iso3}</span>
							    	</i>
							    }
						    </div>
						</div>
						<div className="helper">
							<label htmlFor="name">Name</label>
						    <input value={ this.state.name } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="name" name="name" placeholder="Name" />
						    { errors.name &&
								<i className="text">
							    	<i className="fa fa-warning"></i> 
							    	<span className="text-tone-primary">{errors.name}</span>
							    </i>
							}
						</div>
						<div className="helper">
							<label htmlFor="nicename">Nice Name</label>
						    <input value={ this.state.nicename } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="nicename" name="nicename" placeholder="Nice Name" />
						    { errors.nicename &&
								<i className="text">
							    	<i className="fa fa-warning"></i> 
							    	<span className="text-tone-primary">{errors.nicename}</span>
							    </i>
							}
						</div>
						<div className="row">
						    <div className="helper">
						    	<label htmlFor="numcode">Numcode</label>
						    	<input value={ this.state.numcode } onChange={ this.handleFieldChange } type="tel" autoComplete="off" className="bordered rounded" id="numcode" name="numcode" placeholder="Number Code" />
						    	{ errors.numcode &&
							    	<i className="text">
							    		<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.numcode}</span>
							    	</i>
							    }
						    </div>
						    <div className="helper">
						    	<label htmlFor="phonecode">Phone Code</label>
						    	<input value={ this.state.phonecode } onChange={ this.handleFieldChange } type="tel" autoComplete="off" className="bordered rounded" id="phonecode" name="phonecode" placeholder="Phone Code" />
						    	{ errors.phonecode &&
							    	<i className="text">
							    		<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.phonecode}</span>
							    	</i>
							    }
						    </div>
						</div>
					   	<div className="row grouped middle">
						    <div className="helper">
						    	<label htmlFor="status">Status</label>
							   	<div className="row">
							   	 	<label className="radio">
										<div className="inputs">
											<input type="radio" name="admin_status" checked={ !this.state.status }  onChange={ (e)=>this.setState({status: false}) }/>
											<span className="dot"></span>
										</div>
										<span className="label">Disabled</span>
									</label>
									<label className="radio">
										<div className="inputs">
											<input type="radio" name="admin_status" checked={ this.state.status } onChange={ (e)=>this.setState({status: true}) }/>
											<span className="dot"></span>
										</div>
										<span className="label">Enabled</span>
									</label>
							   </div>
							 </div>
						</div>
						<button className={ `rounded margin top-two ${ this.state.isSubmitting  ? 'bordered disabled' : 'fill-primary' }` } disabled={ this.state.isSubmitting }><i className="fa-plus*circle"></i> Save New Area</button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

export default CPAddArea;