  // DOM manipulation element
import { 
getElement,
getAllElements,
toggleBodyScroll,
toggleable,
addClass,
removeClass,
hasClass 
} from './helpers.js'

  // STICKY ELEMENTS
  const scrollY = function () {
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = (document.compactMode || "") === "CSS1Compat";
    return supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
  };

 var elements = document.querySelectorAll("[data-sticky]");
  for (var i = 0; i < elements.length; i++) {
    (function (element) {
      var elementCords = element.getBoundingClientRect();
      var offset = parseInt(element.getAttribute("data-offset") || 0, 10);
      var top = elementCords.top + scrollY();
      var width = elementCords.width;
      var height = elementCords.height;

      var fakeElement = document.createElement("div");
      fakeElement.style.height = height+'px';

      var onScroll = function () {
        var hasScrollClass = element.classList.contains("fixed");
        // if ( scrollY() > top - offset && !hasScrollClass ) {
        if (scrollY() > offset && !hasScrollClass) {
          addClass(element, "fixed");
          // addClass(element, 'shadowed')
          // element.parentNode.insertBefore(fakeElement, element);
          element.style.top = offset + "px";
        } else if (scrollY() < top - offset && hasScrollClass) {
          removeClass(element, "fixed");
           // element.parentNode.removeChild(fakeElement);
        } else if (scrollY() == offset && hasScrollClass) {
          removeClass(element, "fixed");
          // removeClass(element, 'shadowed')
          element.parentNode.removeChild(fakeElement);
        }
      };
      window.addEventListener("scroll", onScroll);
    })(elements[i]);
  }