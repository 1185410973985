// To enable for dev build and production

if ('serviceWorker' in navigator) {
   window.addEventListener('load', () => {
     navigator.serviceWorker.register('./assets/service-worker.js').then(registration => {
       // console.log('SW registered: ', registration);
     }).catch(registrationError => {
       // console.log('SW registration failed: ', registrationError);
     });
   });
}