import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ChoiceUser extends Component {
	selectUser = (e) => {
		e.preventDefault();
		let collaborator = e.target.dataset;
		this.props.selectUser(collaborator);
	} 
	render() {
		return (
			<div className="salade">
				<ul className="cp_add_user_type">
					<li>
						<button onClick = { this.selectUser } data-collab='admin'>Admin</button>
					</li>
					<li>
						<button onClick = { this.selectUser } data-collab='franchisee'>Franchisee</button>
					</li>
					<li>
						<button onClick = { this.selectUser } data-collab='mt'>Master Technician</button>
					</li>
					<li>
						<button onClick = { this.selectUser } data-collab='callagent'>Call Agent</button>
					</li>
					<li>
						<button onClick = { this.selectUser } data-collab='supplier'>Supplier</button>
					</li>
				</ul>
			</div>
		);
	}
}

export default ChoiceUser;