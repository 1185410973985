import React, { Component } from 'react';
import axios from 'axios';

import Spinner from './Spinner';

class CSESExamQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      answers: [],
      isFetching: false,
      user_answers: [],
    }
    let request_configs = {
      headers: {
        'Content-Type': 'application/json',
         Authorization: 'Bearer ' + API_KEY, //the token is a variable which holds the token
       }
    }
  }

componentDidUpdate(prevProps) {

  if(prevProps.question !== this.props.question) {
    this.setState( { isFetching : true } );
    this.setState({ question: this.props.question });
    // get question answers
    axios.get(API_DOMAIN + '/onboarding/answers/'+this.props.question.id, this.request_configs)
    .then((res) => {
      if(res.data.status){
        let data = res.data.data;
        this.setState({ answers: data });
        this.setState( { isFetching : false } );
      }else{
        // console.log('error')
      }
    })
    .catch((err)=>{
      // console.log(err)
    });
  }
}


componentDidMount(){
  this.setState({ question: this.props.question })
  this.setState( { isFetching : true } );
  // get first question answers
  axios.get(API_DOMAIN + '/onboarding/answers/'+this.props.question.id, this.request_configs)
  .then((res) => {
    if(res.data.status){
      let data = res.data.data;
      this.setState({ answers: data });
       this.setState( { isFetching : false } );
    }else{
      // console.log('error')
    }
  })
  .catch((err)=>{
    // console.log(err)
  });
}

answer = (ev) => {

  this.state.user_answers.forEach( e =>{
    if(e.q == this.state.question.id){
      let ua = this.state.user_answers.map( current_answer => {
        if(current_answer.q == this.props.question.id){
          return Object.assign({}, current_answer, {a: ev.target.value});
        }else{
          return current_answer;
        }
      });
      this.setState({user_answers: ua});
    }
  })

  if(this.state.user_answers.length == 0){
    let { user_answers } = this.state;
    user_answers.push({q:this.state.question.id, a:ev.target.value});
    this.setState({user_answers: user_answers});
  }

  let dublicate = 0;
  this.state.user_answers.forEach(e => {
    if(e.q == this.state.question.id)
      dublicate++;
  });

  if(!dublicate){
    let { user_answers } = this.state;
    user_answers.push({q:this.state.question.id, a:ev.target.value});
    this.setState({user_answers: user_answers});
  }

  this.props.answer(this.state.user_answers);
  
}

  render() {
    let question = this.props.question;
    return (
      <React.Fragment>
        <div className="grouped spread middle margin top-two">
            <h3>{ question.question_text }</h3>
            <span className="panel bordered primary pill">
              <span className="icon"><i className="fa fa-share"></i></span>
              <span className="message uppercase">{ this.props.cursor + 1 }/{ this.props.total }</span>
            </span>
          </div>
          <p>{ question.comment }</p>
          <div className="quiz-questions">
            { this.state.isFetching && <Spinner /> }
            { 
                this.state.answers.map( (answer) => {

                let input = <label className="radio" key={ answer.id }>
                            <div className="inputs">
                              <input type="radio" name="radio" onChange={ this.answer } defaultValue={ answer.id }/>
                              <span className="dot"></span>
                            </div>
                            <span className="label">{ answer.answer_text }</span>
                          </label>;

                this.state.user_answers.forEach( (a) => {
                  if(question.id == a.q && a.a == answer.id){
                    input = <label className="radio" key={ answer.id }>
                              <div className="inputs">
                                <input type="radio" name="radio" checked onChange={ this.answer } defaultValue={ answer.id }/>
                                <span className="dot"></span>
                              </div>
                              <span className="label">{ answer.answer_text }</span>
                            </label>
                  }
                })

                return input;
              })
            }
          </div>
      </React.Fragment>
    );
  }

}

export default CSESExamQuestion;
