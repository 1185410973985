import React, { Component } from 'react';

class Success extends Component {
	constructor(props){
		super(props);
		console.log(this.props)
	}

	render(){
		console.log(this.props)
		return (
				<div className="text text-tone-secondary center margin top-two">
					<i className="fa fa-check-circle fa-3x"></i>
					<h2 className="text center">Success!</h2>
					<p className="text text-tone-dark">{ this.props.message }</p>
					<br/>
				</div>
			);
	}
}

export default Success;