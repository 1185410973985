import React, {Component} from 'react';
// Custom module import 
import { 
		validEmailRegex , 
		objectValuesEmpty, 
		flash 
	} from './../helpers.js'

class CustomerRegistrationForm extends Component{

	constructor(props){
		super(props);
		this.state = {
			firstname:'',
			lastname:'',
			email:'',
			telephone:'',
			password:'',
			type:'',
			errors: {
		        firstname: '',
		        lastname: '',
		        email: '',
				telephone: '',
		        password: '',
		    },
		    isSubmitting: false,
		}
	}

	// Handle registration form
	handleCustomerRegistrationSubmit = event => {
		event.preventDefault();
		// Verify for errors again
		// And Display errors under the empty fields
    	let errors = this.state.errors;
    	let state = Object.assign({}, this.state);
    	Object.entries(state).map((field) => {
    		let toValidate = ['firstname', 'lastname', 'email', 'telephone', 'password'];
    		if(toValidate.includes(field[0])){
    			errors.[field[0]] = state.[field[0]] == '' || state.[field[0]] == undefined || state.[field[0]] == null
	            ? `${ field[0].charAt(0).toUpperCase()+field[0].slice(1) } is Required`
	            : '';
    		}
    	});

		if( objectValuesEmpty(this.state.errors) ) {
			// disable submit button
			// this.setState({ isSubmitting : true })

			// Send form data
			const data = {
				firstname: this.state.firstname,
				lastname:  this.state.lastname,
				email:  this.state.email,
				telephone:  this.state.telephone,
				password:  this.state.password,
				role:  this.state.type,
			};
			this.props.handleRegisterationForm(data);
	    }else{
	    	// reactivate button
	    	this.setState({isSubmitting: false});

	    	// Show message
	    	const theme = 'panel bordered primary rounded';
	    	const icon =`fa fa-times-circle`;
	    	const message ='Kindly fix the following errors';
  			flash(theme, icon, message, '#alert');
	    }
	}

	// Handle input field change
	handleFieldChange = (event) => {
		event.preventDefault();

		// Validate form
		const { name, value } = event.target;
		
		// Fill form field
		this.setState( { [name] : value } );
		// Check for errors
		// TODO: do more validation
		let errors = this.state.errors;
		switch(name){
			case 'firstname':
				errors.firstname = 
	          	value == '' || value == undefined
	            ? 'First Name Required'
	            : '';
	        break;

	        case 'lastname':
				errors.lastname = 
	          	value == '' || value == undefined
	            ? 'Last Name Required'
	            : '';
	        break;

	        case 'email':
				errors.email = 
	          	value == '' || value == undefined || !validEmailRegex.test(value)
	            ? 'Provide a valid Email Address'
	            : '';
			break;

	        case 'telephone':
				errors.telephone = 
	          	value == '' || value == undefined
	            ? 'Provide your Telephone number'
	            : '';
	        break;

	        case 'password':
				errors.password = 
	          	value == '' || value == undefined
	            ? 'Create a new password before'
	            : '';
	        break;
		}
		this.setState({errors, [name]: value});

	}

	componentDidMount(props){
		this.setState({ type: this.props.userType });
	}

	render(){
		let errors = this.state.errors;
		return (
			<React.Fragment>
				<form className="salade" onSubmit={ this.handleCustomerRegistrationSubmit }>
					<p className="text small text-tone-light">Fill in the registration data. It will take a couple of minutes. All you need is a phone number and an e-mail address.</p>
					<div id="alert" className="hide">
						<span className="icon"><i className="" id="icon"></i></span>
						<span className="message" id="message"></span>
					</div>
					<div id="customerForm" className="grouped row spread bottom">
						<div>
							<div className="row">
							    <div className="helper">
							    	<label htmlFor="firstname">First Name</label>
							    	<input value={ this.state.firstname } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="firstname" name="firstname" placeholder="Sholawa" />
							    	{ errors.firstname &&
								    	<i className="text">
								    	<i className="fa fa-question-circle"></i> 
								    		<span className="text-tone-primary">{errors.firstname}</span>
								    	</i>
								    }
							    </div>
							    <div className="helper">
							    	<label htmlFor="lastname">Last Name</label>
							    	<input value={ this.state.lastname } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="lastname" name="lastname" placeholder="Bamiyo" />
							    	{ errors.lastname &&
								    	<i className="text">
								    	<i className="fa fa-question-circle"></i> 
								    		<span className="text-tone-primary">{errors.lastname}</span>
								    	</i>
								   	}
							    </div>
							</div>
							<div className="helper">
						    	<label htmlFor="email">Email Address</label>
						    	<input value={ this.state.email } onChange={ this.handleFieldChange } type="email" autoComplete="off" className="bordered rounded" id="email" name="email" placeholder="hello@fixmaster.com.ng" />
						    	{ errors.email &&
							    	<i className="text">
							    	<i className="fa fa-question-circle"></i> 
							    		<span className="text-tone-primary">{errors.email}</span>
							    	</i>
							    }
						    </div>
						    <div className="helper">
						    	<label htmlFor="telephone">Phone Number</label>
						    	<input value={ this.state.telephone } onChange={ this.handleFieldChange } type="tel" autoComplete="off" className="bordered rounded" id="telephone" name="telephone" placeholder="+234868767868" />
						    	{ errors.telephone &&
							    	<i className="text">
							    	<i className="fa fa-question-circle"></i> 
							    		<span className="text-tone-primary">{errors.telephone}</span>
							    	</i>
							    }
						    </div>
						    <div className="helper">
						    	<label htmlFor="password">Password</label>
						    	<input value={ this.state.password } onChange={ this.handleFieldChange } type="password" autoComplete="off" className="bordered rounded" id="password" name="password" placeholder="secure_password" />
						    	{ errors.password &&
							    	<i className="text">
							    	<i className="fa fa-question-circle"></i> 
							    		<span className="text-tone-primary">{errors.password}</span>
							    	</i>
							    }
						    </div>
						</div>
						<aside className="text center margin">
							<div className="text panel home-panel-security">
								<span className="icon">
									<i className="fa fa-lock fa-2x"></i>
								</span>
								<p className="small message">We take privacy issues seriously. You can be sure that your personal data is securely protected.</p>
							</div>
							<button className={ `rounded margin top-two ${ this.state.isSubmitting  ? 'bordered disabled' : 'fill-primary' }` } disabled={ this.state.isSubmitting }>Signup</button>
							<p className="primary">
								Already have an account, login <a href="" className="primary uppercase">here</a>
							</p>
						</aside>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

export default CustomerRegistrationForm;