import React, { Component } from 'react';

class Spinner extends Component {
	render(){
		return (
				<span className='spinner'>
					<span className="spin"></span>
				</span>
			);
	}
}

export default Spinner;