import React, { Component } from 'react';
import ProgressRing from './ProgressRing';
import CSEExamQuestion from './CSEExamQuestion';

import axios from 'axios';

class CSEExam extends Component {

	constructor(props){
		super(props);
		this.state =  {
			progress: 100,
			minutes: '00',
			seconds: '00',
			duration_secs:0,
			duration: 0,
			strokeColor: 'green',
			instructed: false,
			quiz: null,
			questions: [],
			q_cursor:0,
			nonext: false,
			noprev: true,
			quiz_answers: {},
			end_exam: false,
			tick: 1000,
			t_todo: 0,
		}

		let request_configs = {
			headers: {
				'Content-Type': 'application/json',
			   Authorization: 'Bearer ' + API_KEY, //the token is a variable which holds the token
			 }
		}
	}


	componentDidMount(){
		let duration = 30;
		// Get CSE quiz data
		axios.get(API_DOMAIN + '/onboarding/quiz', this.request_configs)
		.then((res) => {
			if(res.data.status){
				let data = res.data.data;
				this.setState({ quiz: data });
				this.setState({ duration: data.duration });

				let time_to_do = data.duration * 60 ;
				this.setState({ t_todo: time_to_do });

				// Get quiz questions
				axios.get(API_DOMAIN + '/onboarding/quiz/'+data.id+'/questions', this.request_configs)
				.then((res) => {
					if(res.data.status){
						let data = res.data.data;
						this.setState({ questions: data });
					}else{
						console.log('error')
					}
				})
				.catch((err)=>{
					console.log(err)
				});
			}else{
				// console.log('error')
			}
		})
		.catch((err)=>{
			// console.log(err)
		});
		
	}


	prevQuestion = () => {
		if( this.state.q_cursor > 0 )
			this.setState({ q_cursor: this.state.q_cursor - 1 });

		if( 1 == this.state.q_cursor ){
			this.setState({ noprev: true });
		}else{
			this.setState({ nonext: false });
		}
	}

	nextQuestion = () => {
		if( this.state.questions.length - 1 > this.state.q_cursor )
			this.setState({ q_cursor: this.state.q_cursor + 1 });

		if( this.state.questions.length - 2 == this.state.q_cursor ){
			this.setState({ nonext: true });
		}else{
			this.setState({ noprev: false });
		}	
	}

	answer = data => {
		this.setState({quiz_answers: data});
		// send data to backend
		axios.post(API_DOMAIN + '/onboarding/answer/'+this.state.quiz.id, {data: data, elapsed: this.state.duration_secs, ended: this.state.end_exam }, this.request_configs)
		.then((res) => {})
		.catch((err)=>{
			console.log(err)
		});
	}


	initEnd = () => {
		this.setState({ end_exam: true,  tick: 1000000});

		// This is crasy!!
		// React requires a delay to update state
		setTimeout( () => {
			this.endExam();
		}, 2000)
	}

	endExam = () => {
		// End exam
    	this.setState({ end_exam: true,  tick: 1000000});

    	// Submit form
    	this.answer(this.state.quiz_answers);

    	// kill clock speed
    	this.setState({ duration_secs: 0, seconds: 0,  progress: 100 });
    	this.startTimer(this.state.tick);
	}

	startTimer = (duration) => {
	    var timer = duration, minutes, seconds;

	    setInterval( () => {
	        let minutes = parseInt(timer / 60, 10);
	        let seconds = parseInt(timer % 60, 10);

	        let min = minutes < 10 ? "0" + minutes : minutes;
	        let sec = seconds < 10 ? "0" + seconds : seconds;

	        if (--timer < 0) {
	            timer = duration;
	        }

	        let total_duration_seconds = this.state.duration * 60;

	        if(this.state.duration_secs > (total_duration_seconds / 2) )
	        	this.setState({ strokeColor: 'lightgreen'});

	        if(this.state.duration_secs >= (total_duration_seconds - 10) )
	        	this.setState({ strokeColor: 'tomato'});

	        // percentage progress
			let a_slice = 100 / total_duration_seconds

	        this.setState({ minutes: min, seconds: sec});
	        this.setState({ duration_secs: this.state.duration_secs + 1 });
	        this.setState({ progress: this.state.progress - a_slice});

	        if( this.state.duration_secs >= total_duration_seconds && !this.state.end_exam ){
	        	// End the exam automatically
	        	this.endExam();	        	
	        }

	    }, this.state.tick);
	}

	startExam = duration => {
		this.setState({ instructed: true });
		this.startTimer(duration);
		// signal backend
		axios.post(API_DOMAIN + '/onboarding/start/'+this.state.quiz.id, {start: true }, this.request_configs)
		.then((res) => {})
		.catch((err)=>{
			console.log(err)
		});
	}


	render() {
		return (
			this.state.instructed 
			?
				!this.state.end_exam ?
				<React.Fragment>
					<p className="text right">Exam automatically submits in </p>
					<div className="grouped spread middle">
						<button onClick = { this.initEnd } className={ `rounded  + ${this.state.quiz_answers.length > 0 ? 'fill-primary':'bordered disabled'} `} >Submit</button>
						<ProgressRing 
							radius = { `51`}  
							stroke = { '1' }  
							progress = { this.state.progress }
							stroke_color = { this.state.strokeColor }
							minutes = { this.state.minutes }
							seconds = { this.state.seconds }
							/>
					</div>
			
					<CSEExamQuestion answer = { this.answer }  question = { this.state.questions[this.state.q_cursor] } total = { this.state.questions.length } cursor = { this.state.q_cursor } />

					<div className="grouped center right">
						<button onClick={ this.prevQuestion } className={ this.state.noprev ? 'disabled' : '' }><i className="fa fa-chevron-left"></i> Back</button>
						<button onClick={ this.nextQuestion } className={ this.state.nonext ? 'disabled' : '' }>Next <i className="fa fa-chevron-right "></i></button>
					</div>
				</React.Fragment>
				: 
				<React.Fragment>
					<h2>Exam has ended</h2>
					<a href="">Continue</a> to your interview
				</React.Fragment>
			: 
			<React.Fragment>
				<h2>Examination Instructions</h2>
				<p>Once you start do not close this tab or your browser. Stopping at any point will be considered as your final grade... </p>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eleifend ornare et urna integer molestie odio. Mi molestie in mi augue. Quis amet morbi dignissim proin tortor hendrerit. Risus sem nibh nulla dui. Suspendisse imperdiet elit non, diam sagittis, et diam. 
					Tristique vel tortor commodo facilisi. Aliquam feugiat maecenas tellus volutpat lacus, libero amet. Consectetur sit diam et molestie vitae mi ut. Accumsan, tellus enim adipiscing aliquam odio accumsan, bibendum imperdiet justo. Nulla augue mattis quis massa vel. Arcu gravida et dignissim egestas ultrices non sit id et. Vulputate lacinia dui, a viverra senectus mauris enim ut lacus. Quis libero congue rhoncus mi fermentum ut facilisis. Non potenti sem dui nulla morbi id malesuada nunc. Orci integer pulvinar nunc, phasellus.
				</p>
				<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos quisquam laudantium sequi possimus tempore, perferendis adipisci eum libero inventore sapiente minus ipsum quod dolorum, qui obcaecati non labore autem harum?</p>
				<hr/>
				<p>
					<button className="fill-primary rounded" onClick={ () => this.startExam(this.state.t_todo) }>Start Exam</button>
				</p>
			</React.Fragment>
		);
	}
}

export default CSEExam;