import React, {Component} from 'react';
// Custom module import 
import { 
		validEmailRegex , 
		objectValuesEmpty, 
		flash 
	} from './../helpers.js'

class CPAddSupplier extends Component{

	constructor(props){
		super(props);
		this.state = {
			firstname:'',
			lastname:'',
			email:'',
			telephone:'',
			password:'',
			role:'supplier',
			status:false,
			dob: '',
	        bname: '',
	        cac: '',
	        description: '',
			errors: {
		        firstname: '',
		        lastname: '',
		        email: '',
				telephone: '',
		        password: '',
		        dob: '',
		        bname: '',
		        cac: '',
		        description: '',
		    },
		    isSubmitting: false,
		}
	}

	// Handle registration form
	CPSaveNewSupplier = event => {
		event.preventDefault();
		
		// Verify for errors again
		// And Display errors under the empty fields
    	let errors = this.state.errors;
    	let state = Object.assign({}, this.state);
    	Object.entries(state).map((field) => {
    		let toValidate = ['firstname', 'lastname', 'email', 'telephone', 'password', 'dob', 'bname', 'cac', 'description'];
    		if(toValidate.includes(field[0])){
    			errors.[field[0]] = state.[field[0]] == '' || state.[field[0]] == undefined || state.[field[0]] == null
	            ? `${ field[0].charAt(0).toUpperCase()+field[0].slice(1) } is Required`
	            : '';
    		}
    	});

		if( objectValuesEmpty(this.state.errors) ) {
			
			// disable submit button
			this.setState({ isSubmitting : true })

			// Send form data
			const data = {
				firstname: this.state.firstname,
				lastname: this.state.lastname,
				email: this.state.email,
				telephone: this.state.telephone,
				password: this.state.password,
				role: this.state.role,
				status: this.state.status,
				dob: this.state.dob,
		        bname: this.state.bname,
		        cac: this.state.cac,
		        description: this.state.description,
			};
			this.props.addSupplier(data);

	    }else{
	    	// reactivate button
	    	this.setState({isSubmitting: false});

	    	// Show message
	    	const theme = 'panel bordered primary rounded';
	    	const icon =`fa fa-times-circle`;
	    	const message ='Kindly fix the following errors';
  			flash(theme, icon, message, '#alert');
	    }
	}

	// Handle input field change
	handleFieldChange = (event) => {
		event.preventDefault();

		// Validate form
		const { name, value } = event.target;
		
		// Fill form field
		this.setState( { [name] : value } );

		// Check for errors
		// TODO: do more validation
		let errors = this.state.errors;
		switch(name){
			case 'firstname':
				errors.firstname = 
	          	value == '' || value == undefined
	            ? 'First Name Required'
	            : '';
	        break;

	        case 'lastname':
				errors.lastname = 
	          	value == '' || value == undefined
	            ? 'Last Name Required'
	            : '';
	        break;

	        case 'email':
				errors.email = 
	          	value == '' || value == undefined || !validEmailRegex.test(value)
	            ? 'Provide a valid Email Address'
	            : '';
			break;

	        case 'telephone':
				errors.telephone = 
	          	value == '' || value == undefined
	            ? 'Provide your Telephone number'
	            : '';
	        break;

	        case 'password':
				errors.password = 
	          	value == '' || value == undefined
	            ? 'Create a new password before'
	            : '';
	        break;
	        case 'dob':
				errors.dob = 
	          	value == '' || value == undefined
	            ? 'When was the business established'
	            : '';
	        break;
	        case 'bname':
				errors.bname = 
	          	value == '' || value == undefined
	            ? 'Provide the business name'
	            : '';
	        break;
	        case 'cac':
				errors.cac = 
	          	value == '' || value == undefined
	            ? 'Provide the CAC number'
	            : '';
	        break;
	        case 'description':
				errors.description = 
	          	value == '' || value == undefined
	            ? 'Write a bit about the supplier'
	            : '';
	        break;
		}

		// Set errors in state
		this.setState({errors, [name]: value});

	}

	render(){
		let errors = this.state.errors;
		let inline = {
			margin: {
				margin:0,
				marginRight:'1rem',
			},
		}

		return (
			<React.Fragment>
				<form id="customerForm" className="salade grouped row spread bottom" onSubmit={ this.CPSaveNewSupplier }>
					<div>
						<div id="alert" className="hide">
							<span className="icon"><i className="" id="icon"></i></span>
							<span className="message" id="message"></span>
						</div>
						<div className="row">
						    <div className="helper">
						    	<label htmlFor="firstname">First Name</label>
						    	<input value={ this.state.firstname } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="firstname" name="firstname" placeholder="Sholawa" />
						    	{ errors.firstname &&
							    	<i className="text">
							    	<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.firstname}</span>
							    	</i>
							    }
						    </div>
						    <div className="helper">
						    	<label htmlFor="lastname">Last Name</label>
						    	<input value={ this.state.lastname } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="lastname" name="lastname" placeholder="Bamiyo" />
						    	{ errors.lastname &&
							    	<i className="text">
							    	<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.lastname}</span>
							    	</i>
							   	}
						    </div>
						</div>
						<div className="helper">
					    	<label htmlFor="email">Email Address</label>
					    	<input value={ this.state.email } onChange={ this.handleFieldChange } type="email" autoComplete="off" className="bordered rounded" id="email" name="email" placeholder="supplier@fixmaster.com.ng" />
					    	{ errors.email &&
						    	<i className="text">
						    	<i className="fa fa-warning"></i> 
						    		<span className="text-tone-primary">{errors.email}</span>
						    	</i>
						    }
					    </div>
					    <div className="helper">
					    	<label htmlFor="telephone">Phone Number</label>
					    	<input value={ this.state.telephone } onChange={ this.handleFieldChange } type="tel" autoComplete="off" className="bordered rounded" id="telephone" name="telephone" placeholder="+234868767868" />
					    	{ errors.telephone &&
						    	<i className="text">
						    	<i className="fa fa-warning"></i> 
						    		<span className="text-tone-primary">{errors.telephone}</span>
						    	</i>
						    }
					    </div>
					    <div className="helper">
					    	<label htmlFor="password">Password</label>
					    	<input value={ this.state.password } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="password" name="password" placeholder="secure_password" />
					    	{ errors.password &&
						    	<i className="text">
						    	<i className="fa fa-warning"></i> 
						    		<span className="text-tone-primary">{errors.password}</span>
						    	</i>
						    }
					    </div>
					    <div className="helper">
					    	<label htmlFor="dob">Business Established on</label>
					    	<input type="date" name="dob" id="dob" onChange={ this.handleFieldChange }  className="bordered rounded"/>
					    	{ errors.dob &&
						    	<i className="text">
						    	<i className="fa fa-warning"></i> 
						    		<span className="text-tone-primary">{errors.dob}</span>
						    	</i>
						    }
					    </div>
					    <div className="row">
						    <div className="helper">
						    	<label htmlFor="bname">Business Name</label>
						    	<input type="text" name="bname" id="bname"  onChange={ this.handleFieldChange } className="bordered rounded"  />
						    	{ errors.bname &&
							    	<i className="text">
							    	<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.bname}</span>
							    	</i>
							    }
						    </div>
						    <div className="helper">
						    	<label htmlFor="cac">CAC Number</label>
						    	<input type="text" name="cac" id="cac"  onChange={ this.handleFieldChange } className="bordered rounded"  />
						    	{ errors.cac &&
							    	<i className="text">
							    	<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.cac}</span>
							    	</i>
							   	}
						    </div>
						</div>

					    <div className="helper">
					    	<label htmlFor="description">Business Description</label>
					    	<textarea name="description" id="description"  onChange={ this.handleFieldChange } className="bordered rounded"></textarea>
					    	{ errors.description &&
						    	<i className="text">
						    	<i className="fa fa-warning"></i> 
						    		<span className="text-tone-primary">{errors.description}</span>
						    	</i>
						    }
					    </div>
					    <div className="helper">
					    	<label htmlFor="role">Status </label>
						   <div className="grouped left">
						   	 	<label className="radio" style={ inline.margin }>
									<div className="inputs">
										<input type="radio" name="admin_status" checked={ this.state.status } onChange={ (e)=>this.setState({status: true}) }/>
										<span className="dot"></span>
									</div>
									<span className="label">Enabled</span>
								</label>
								<label className="radio" style={ inline.margin }>
									<div className="inputs">
										<input type="radio" name="admin_status" checked={ !this.state.status }  onChange={ (e)=>this.setState({status: false}) }/>
										<span className="dot"></span>
									</div>
									<span className="label">Disabled</span>
								</label>
						   </div>
						</div>

						<button className={ `rounded margin top-two ${ this.state.isSubmitting  ? 'bordered disabled' : 'fill-primary' }` } disabled={ this.state.isSubmitting }><i className="fa fa-plus-circle"></i> Save new Supplier</button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

export default CPAddSupplier;