import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import UserSignin from './UserSignin';
import CustomerRegistrationForm from './CustomerRegistrationForm';
import CustomerVerificationForm from './CustomerVerificationForm';
import CPAddAdmin from './CPAddAdmin';
import CPAddFranchisee from './CPAddFranchisee';
import CPAddMt from './CPAddMt';
import CPAddCallAgent from './CPAddCallAgent';
import CPAddSupplier from './CPAddSupplier';
import CPAddApplication from './CPAddApplication';
import CPAddArea from './CPAddArea';
import CPAddBank from './CPAddBank';
import CPAddCountry from './CPAddCountry';
import CPAddDegree from './CPAddDegree';
import CPAddGender from './CPAddGender';
import CPAddLanguage from './CPAddLanguage';
import CPAddLGA from './CPAddLGA';
import CPAddMaritalStatus from './CPAddMaritalStatus';
import CPAddReligion from './CPAddReligion';
import CPAddState from './CPAddState';
import CPAddTerritory from './CPAddTerritory';
import CPAddTown from './CPAddTown';
import ChoiceUser from './ChoiceUser';
import Success from './Success';
import Spinner from './Spinner';

import axios from 'axios';

// Custom module import 
import { flash  } from './../helpers.js'

class Modal extends Component {

	constructor(props){
		super(props);
		this.state = {
			isSignedin: false,
			registered: false,
			isSubmitting: false,
			isOTPVerified: false,
			content: '',
			pinTel: '',
			success_message: '',
		}
		let request_configs = {
			headers: {
				'Content-Type': 'application/json',
			   Authorization: 'Bearer ' + API_KEY, //the token is a variable which holds the token
			 }
		}
	}

	closeModal = (location) => {
		 ReactDOM.unmountComponentAtNode(location);
		 document.querySelector('body').classList.remove('lock');
	}

	// TODO: Collapse all these methods 
	// into one encapsulation and handle
	// the differences

	// Sign User In
	signUserIn = formData => {
		this.setState({ isSubmitting: true })
		const data = {email: formData.email, password: formData.password, rememberme: formData.rememberMe}
		axios.post(API_DOMAIN + '/users/signin', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			let set =	setTimeout( () => {
	  			// Pass the hand to the OTP screen
					this.setState( { isSignedin: true } );
				}, 3000);

	  			// TODO: Detect role and redirect to appropriate URL
				// Redirect
				window.location.href = res.data.redirect;

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		})
		.catch((err)=>{
			console.log(err)
		});
	}

	// Select usertype
	selectedUserType = (type) =>{
		this.setState({ content : type.collab })
	}

	// Add new Admin from cpanel
	addNewAdmin = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/users/create', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
	  			// Pass the hand to the OTP screen
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new franchisee from cpanel
	addNewFranchisee = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/users/create', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new call agent
	addNewCallAgent = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/users/create', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new supplier
	addNewSupplier = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/users/create', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Users Sign up
	// Handle Registration Form submission
	customerRegistration = (data) => {
		let request_configs = {
			headers: {
				'Content-Type': 'application/json',
			   Authorization: 'Bearer ' + API_KEY, //the token is a variable which holds the token
			 }
		}

		// Call to API to submit data
		if(!this.state.isSubmitting){

			this.setState( {isSubmitting : true } );

			axios.post(API_DOMAIN + '/users/create', data, request_configs)
			.then((res) => {
				// Navigate to next component when response is positive
				if(res.data.status){
					// Show message
			    	const theme = 'panel bordered secondary rounded';
			    	const icon = `fa fa-check-circle`;
			    	const message = res.data.message;
		  			flash(theme, icon, message, '#alert');

		  			// Disbale spinner
		  			this.setState( { isSubmitting : false, pinTel: data.telephone } );

		  			// let set =	setTimeout( () => {
		  				// Pass the hand to the OTP screen
						this.setState( { registered: true } );
					// }, 2000);
				}else{
					this.setState( { isSubmitting : false } );
					const theme = 'panel bordered primary rounded';
			    	const icon = `fa fa-times-circle`;
			    	const message = res.data.message;
		  			flash(theme, icon, message, '#alert');
				}
			})
			.catch((err)=>{
				// Disbale spinner
		  		this.setState( { isSubmitting : false } );

				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = err;
	  			flash(theme, icon, message, '#alert');
			});
		}else{
			console.log('Double submit cought');
		}
	}

	// Add new application
	addNewApplication = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/admin/addapplication', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new bank
	addNewBank = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/admin/addbank', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new country
	addNewCountry = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/admin/addcountry', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new degree
	addNewDegree = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/admin/adddegree', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new gender
	addNewGender = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/admin/addgender', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new language
	addNewLanguage = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/admin/addlanguage', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Add new state
	addNewState = (data) => {
		this.setState({ isSubmitting: true })
		axios.post(API_DOMAIN + '/admin/addstate', data, this.request_configs)
		.then((res) => {
			if(res.data.status){
				// Show message
		    	const theme = 'panel bordered secondary rounded';
		    	const icon = `fa fa-check-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');

	  			// Disbale spinner
	  			this.setState( { isSubmitting : false } );
	  			// let set =	setTimeout( () => {
					this.setState( { content: 'success' } );
				// }, 3000);

			}else{
				this.setState( { isSubmitting : false } );
				const theme = 'panel bordered primary rounded';
		    	const icon = `fa fa-times-circle`;
		    	const message = res.data.message;
	  			flash(theme, icon, message, '#alert');
			}
		});
	}

	// Handle the customer verification PIN
	verifyOTP = pins => {
		let request_configs = {
			headers: {
				'Content-Type': 'application/json',
			   Authorization: 'Bearer ' + API_KEY, //the token is a variable which holds the token
			 }
		}
		let pin_load =  { 
			pin: pins.otp1 + pins.otp2 + pins.otp3 + pins.otp4 + pins.otp5 + pins.otp6,
			telephone: this.state.pinTel
		}

		axios.post(API_DOMAIN + '/users/verify/otp', pin_load, request_configs)
		.then((res) => {
			if(res.data.status){
					// Show message
			    	const theme = 'panel bordered secondary rounded';
			    	const icon = `fa fa-check-circle`;
			    	const message = res.data.message;
		  			flash(theme, icon, message, '#alert');

		  			// Disbale spinner & show success
	  				this.setState( { 
	  					registered: false,  
	  					isSubmitting : false, 
	  					isOTPVerified: true,  
	  					success_message: res.data.message,
	  					content: 'success',
	  				} );
				}else{
					const theme = 'panel bordered primary rounded';
			    	const icon = `fa fa-times-circle`;
			    	const message = res.data.message;
		  			flash(theme, icon, message, '#alert');
				}
		})
		.catch((err)=>{
			console.log(err)
		})
		.then(() => {
			// remove loader
		});
	}

	render(){
		let modal_content = this.props.content !='' ? this.props.content : this.state.content;
		let the_component = '';

		switch(modal_content){
			case '':
			the_component = <ChoiceUser selectUser = { this.selectedUserType }/>
			break;
			case 'signin':
			the_component = <UserSignin theme_footer = { `bordered-top spaced primary` } signin = { this.signUserIn }  isSubmitting = { this.state.isSubmitting } />
			break;
			case 'admin':
			the_component = <CPAddAdmin addAdmin = { this.addNewAdmin }  isSubmitting = { this.state.isSubmitting } />
			break;
			case 'franchisee':
			the_component = <CPAddFranchisee addFranchisee = { this.addNewFranchisee }  isSubmitting = { this.state.isSubmitting } />
			break;
			case 'mt':
			the_component = <CPAddMt addFranchisee = { this.addNewFranchisee }  isSubmitting = { this.state.isSubmitting } />
			break;
			case 'callagent':
			the_component = <CPAddCallAgent addCallAgent = { this.addNewCallAgent } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'supplier':
			the_component = <CPAddSupplier addSupplier = { this.addNewSupplier } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'CustomerRegistrationForm':
			the_component = <CustomerRegistrationForm handleRegisterationForm = { this.customerRegistration } isSubmitting = { this.state.isSubmitting } userType = { this.props.userType } />
			break;
			case 'newapplication':
			the_component = <CPAddApplication addApplication = { this.addNewApplication } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newarea':
			the_component = <CPAddArea addArea = { this.addNewArea } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newbank':
			the_component = <CPAddBank addBank = { this.addNewBank } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newcountry':
			the_component = <CPAddCountry addCountry = { this.addNewCountry } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newdegree':
			the_component = <CPAddDegree addDegree = { this.addNewDegree } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newgender':
			the_component = <CPAddGender addGender = { this.addNewGender } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newlanguage':
			the_component = <CPAddLanguage addLanguage = { this.addNewLanguage } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newlga':
			the_component = <CPAddLGA addLGA = { this.addNewLGA } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newmaritalstatus':
			the_component = <CPAddMaritalStatus addMaritalStatus = { this.addNewMaritalStatus } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newreligion':
			the_component = <CPAddReligion addReligion = { this.addNewReligion } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newstate':
			the_component = <CPAddState addState = { this.addNewState } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newterritory':
			the_component = <CPAddTerritory addTerritory = { this.addNewTerritory } isSubmitting = { this.state.isSubmitting } />
			break;
			case 'newtown':
			the_component = <CPAddTown addTown = { this.addNewTown } isSubmitting = { this.state.isSubmitting } />
			break;

			case 'success':
			the_component = <Success />;
			break
		}

		if(this.state.registered) {
		 	the_component = <CustomerVerificationForm verifyOTP = { this.verifyOTP } />
		}

		if(this.state.isOTPVerified){
		 	the_component = <Success message = { this.state.success_message } />
		}

		let styles = {
			width: {
				width: this.props.width +'px',
			}
		}

		return (
			<div className = "modal show">
				
				<section style={ styles.width } className = { `slot `+ this.props.theme }>
					<div className = "content">
						<header className={`grouped spread ${this.props.theme_header}`}>
							<h2 className="text center home-title"><i className={ this.props.theme_header_icon }></i> { this.props.theme_header_title } </h2>
							<button className = "close"  onClick = { () => this.closeModal(this.props.location) }>
								<i className = "fa fa-times"></i>
							</button>
						</header>
						<section className="body">
							{ the_component }
							{ this.state.isSubmitting && <Spinner />}
						</section>
					</div>
				</section>
			</div>
		);
	}
}

export default Modal;