import React, {Component} from 'react';
// Custom module import 
import { 
		validEmailRegex , 
		objectValuesEmpty, 
		flash 
	} from './../helpers.js'

class CPAddState extends Component{

	constructor(props){
		super(props);
		this.state = {
			countryid:'',
			name:'',
			code:'',
			errors: {
		        countryid: '',
		        name: '',
		        code: '',
		    },
		    isSubmitting: false,
		}
	}

	// Handle registration form
	CPSaveState = event => {
		event.preventDefault();
		// Verify for errors again
		// And Display errors under the empty fields
    	let errors = this.state.errors;
    	let state = Object.assign({}, this.state);
    	Object.entries(state).map((field) => {
    		let toValidate = ['countryid', 'name', 'code'];
    		if(toValidate.includes(field[0])){
    			errors.[field[0]] = state.[field[0]] == '' || state.[field[0]] == undefined || state.[field[0]] == null
	            ? `${ field[0].charAt(0).toUpperCase()+field[0].slice(1) } is Required`
	            : '';
    		}
    	});

		if( objectValuesEmpty(this.state.errors) ) {
			// disable submit button
			this.setState({ isSubmitting : true })

			// Send form data
			this.props.addState({
				countryid: this.state.countryid, 
				name: this.state.name, 
				code: this.state.code, 
			});
	    }else{
	    	// reactivate button
	    	this.setState({isSubmitting: false});

	    	// Show message
	    	const theme = 'panel bordered primary rounded';
	    	const icon =`fa fa-times-circle`;
	    	const message ='Kindly fix the following errors';
  			flash(theme, icon, message, '#alert');
	    }
	}

	// Handle input field change
	handleFieldChange = (event) => {
		event.preventDefault();

		// Get input name and value
		const { name, value } = event.target;
		
		// Fill form field
		this.setState( { [name] : value } );

		// Check for errors
		// TODO: do more validation
		let errors = this.state.errors;
		switch(name){
			case 'countryid':
				errors.countryid = 
	          	value == '' || value == undefined
	            ? 'Country Required'
	            : '';
	        break;

			case 'name':
				errors.name = 
	          	value == '' || value == undefined
	            ? 'State Name Required'
	            : '';
	        break;

	        case 'code':
				errors.code = 
	          	value == '' || value == undefined
	            ? 'State Code Required'
	            : '';
	        break;
		}
		this.setState({errors, [name]: value});
	}

	render(){
		let errors = this.state.errors;

		return (
			<React.Fragment>
				<div id="alert" className="hide">
					<span className="icon"><i className="" id="icon"></i></span>
					<span className="message" id="message"></span>
				</div>
				<form id="customerForm" className="grouped row spread bottom" onSubmit={ this.CPSaveState}>
					<div>
						<div className="row">
							<div className="helper">
								<label htmlFor="name">State Name</label>
							    <input value={ this.state.name } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="name" name="name" placeholder="State Name" />
							    { errors.name &&
									<i className="text">
								    	<i className="fa fa-warning"></i> 
								    	<span className="text-tone-primary">{errors.name}</span>
								    </i>
								}
							</div>
						</div>
						<div className="row">
						    <div className="helper">
						    	<label htmlFor="code">State Code</label>
						    	<input value={ this.state.code } onChange={ this.handleFieldChange } type="tel" autoComplete="off" className="bordered rounded" id="code" name="code" placeholder="State Code" />
						    	{ errors.code &&
							    	<i className="text">
							    		<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.code}</span>
							    	</i>
							    }
						    </div>
						    <div className="helper">
						    	<label htmlFor="countryid">Country</label>
						    	<input value={ this.state.countryid } onChange={ this.handleFieldChange } type="text" autoComplete="off" className="bordered rounded" id="countryid" name="countryid" placeholder="Country" />
						    	{ errors.countryid &&
							    	<i className="text">
							    		<i className="fa fa-warning"></i> 
							    		<span className="text-tone-primary">{errors.countryid}</span>
							    	</i>
							    }
						    </div>
						</div>
						<button className={ `rounded margin top-two ${ this.state.isSubmitting  ? 'bordered disabled' : 'fill-primary' }` } disabled={ this.state.isSubmitting }><i className="fa-plus*circle"></i> Add New State</button>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

export default CPAddState;