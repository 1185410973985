import React, {Component} from 'react';
// Custom module import 
import { 
		validEmailRegex , 
		objectValuesEmpty, 
		flash 
	} from './../helpers.js'

class UserSignin extends Component{

	constructor(props){
		super(props);
		this.state = {
			email:'',
			password:'',
			rememberMe: false,
			errors: {
		        email: '',
				password: '',
		    }
		}
	}


	// Handle PIN verification
	handleSigninSubmit = event => {
		event.preventDefault();
		// Verify for errors again
		// And Display errors under the empty fields
    	let errors = this.state.errors;
    	let state = Object.assign({}, this.state);
    	Object.entries(state).map((field) => {
    		let toValidate = ['email', 'password'];
    		if(toValidate.includes(field[0])){
    			errors.[field[0]] = state.[field[0]] == '' || state.[field[0]] == undefined || state.[field[0]] == null
	            ? `${ field[0].charAt(0).toUpperCase()+field[0].slice(1) } is Required`
	            : '';

	    		if(field[0] == 'email'){
		    		errors.[field[0]] = !validEmailRegex.test(state.[field[0]])
			            ? 'Enter a valid email address'
			            : '';
	    		}
    		}


    		this.setState({errors: errors});
    	});
		if( objectValuesEmpty(this.state.errors) ) {
			// Send form data
			this.props.signin(this.state);
	    }else{
	    	// Show message
	    	const theme = 'panel primary bordered rounded';
	    	const icon =`fa fa-times-circle`;
	    	const message ='Kindly fix the following errors';
  			flash(theme, icon, message, '#alert');
	    }
	}

	handleFieldChange = (event) => {
		event.preventDefault();
		let el = event.target
		
		// get field name and it value
		const { name, value } = el;
		this.setState( { [name] : value } );

		// Check for errors
		// TODO: do more validation
		let errors = this.state.errors;
		switch(name){
			case 'email':
				errors.email = 
	          	value == '' || value == undefined || !validEmailRegex.test(value)
	            ? 'Enter a valid email address'
	            : '';
	        break;

	        case 'password':
				errors.password = 
	          	value == '' || value == undefined
	            ? 'Password is Required'
	            : '';
	        break;
	       
		}
		this.setState({errors, [name]: value});
	}


	render(){

		let errors = this.state.errors;
		let style = {
			marginTop: 0,
		}

		return (
			<React.Fragment>
				<form id="userSignin" onSubmit={ this.handleSigninSubmit }>
					<div className="salade">
						<div id="alert" className="hide">
							<span className="icon"><i id="icon"></i></span>
							<span className="message" id="message"></span>
						</div>
						<div className="helper">
							<label htmlFor="email">Email Address</label>
							<label style={style} className="field-icon bordered default rounded">
								<i className="fa fa-user icon"></i>
								<input type="text" className="rounded" id="email" name="email" autoComplete="off" onChange={this.handleFieldChange}/>
							</label>
							{ errors.email &&
							<span className="text primary">
								<i className="fa fa-warning"></i> 
								<span className="text-tone-primary">{errors.email}</span>
							</span> }
						</div>
						<div className="helper">
							<label htmlFor="password" className="grouped spread center">
								<span>Password</span>
								<a href="">I forgot it</a>
							</label>
							<label  style={style} className="field-icon bordered default rounded">
								<i className="fa fa-lock icon"></i>
								<input type="password" className="rounded" id="password" name="password" autoComplete="off" onChange={this.handleFieldChange}/>
							</label>
							{ errors.password &&
							<span className="text">
								<i className="fa fa-warning"></i> 
								<span className="text-tone-primary">{ errors.password}</span>
							</span>}
						</div>
						<label className="radio">
							<div className="inputs">
								<input type="checkbox" name="rememberMe"  checked={ this.state.rememberMe }  onChange={ (e)=>this.setState({rememberMe: e.target.checked}) }/>
								<span className="box"></span>
							</div>
							<span className="label">Remember me</span>
						</label>
					</div>
				    <footer className={ this.props.theme_footer }>
				    	<button className={ `rounded margin  ${ this.props.isSubmitting  ? 'bordered disabled' : 'primary bordered' }` } disabled={ this.props.isSubmitting }>Sign In</button>
				    </footer>
				</form>
			</React.Fragment>
		);
	}
}

export default UserSignin;