import React, { Component } from 'react';
import axios from 'axios';
import ProgressRing from './ProgressRing';
import Spinner from './Spinner';

class InterviewQuestionaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      answers: [],
      isFetching: false,
      user_answers: [],
    }
    let request_configs = {
      headers: {
        'Content-Type': 'application/json',
         Authorization: 'Bearer ' + API_KEY, //the token is a variable which holds the token
       }
    }
  }




  render() {
    let question = this.props.question;
    return (
      <React.Fragment>
        <div className="grouped spread middle margin top-two">
          <div className="grouped spread bottom margin top-two dialog-left">
              <div className="grouped spread left middle msg-box">
                <div className="msg">
                  <h5>Fixmaster Bot (Host)</h5>
                  <i className="fa fa-comment-o"></i>
                  <blockquote className="panel pill rounded">
                    Hi, Kindly Introduce yourself
                  </blockquote>
                </div>
              </div>
              <div className="dialog-right">
                <ProgressRing 
                  radius = { `30`}  
                  stroke = { '0' }  
                  progress = { this.state.progress }
                  stroke_color = { this.state.strokeColor }
                  minutes = { this.state.minutes }
                  seconds = { this.state.seconds }
                  />
                <button onClick={ this.nextQuestion } className={  this.state.nonext ? 'disabled' : '' }>Next question <i className="fa fa-arrow-right "></i></button>
              </div>
            </div>
        </div>
      </React.Fragment>
    );
  }

}

export default InterviewQuestionaire;
