
// METHODS

export let getElement = function (el) {
  return document.querySelector(el);
};

export let getAllElements = function (el) {
  return document.querySelectorAll(el);
};

export let toggleBodyScroll = function () {
  getElement('body').classList.toggle('freeze');
 }

export let toggleable = function (el, className) {
  el.classList.toggle(className);
};

export let addClass = function (el, className) {
  el.classList.add(className);
};

export let removeClass = function (el, className) {
  el.classList.remove(className);
};

export let hasClass = function (el, classe) {
  return el.classList.contains(classe);
};

export let isObjectEmpty = function (obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export const objectValuesEmpty = errors => {
  let valid = true;
  Object.values(errors).forEach(value =>  {
  	if(value.length > 0)
  		valid = false
  });

  return valid;
};

export const flash = (type, icon, message, target) => {
	const target_el = document.querySelector(target);
	target_el.setAttribute('class', type);

	const target_el_icon = document.querySelector(`${target} #icon`);
	target_el_icon.setAttribute('class', icon);

	const target_el_msg = document.querySelector(`${target} #message`);
	target_el_msg.innerHTML='';
	target_el_msg.insertAdjacentHTML('afterbegin', message);
}
