import React, {Component} from 'react';
// Custom module import 
import { 
		validEmailRegex , 
		objectValuesEmpty, 
		flash 
	} from './../helpers.js'

class CustomerVerificationForm extends Component{

	constructor(props){
		super(props);
		this.state = {
			otp1:'',
			otp2:'',
			otp3:'',
			otp4:'',
			otp5:'',
			otp6:'',
			errors: {
		        otp1:'',
				otp2:'',
				otp3:'',
				otp4:'',
				otp5:'',
				otp6:'',
		    },
		    isSubmitting: false,
		}
	}


	// Handle PIN verification
	handleCustomerVerificationSubmit = event => {
		event.preventDefault();
		// Verify for errors again
		// And Display errors under the empty fields
    	let errors = this.state.errors;
    	let state = Object.assign({}, this.state);
    	Object.entries(state).map((field) => {
    		let toValidate = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
    		if(toValidate.includes(field[0])){
    			errors.[field[0]] = state.[field[0]] == '' || state.[field[0]] == undefined || state.[field[0]] == null
	            ? `${ field[0].charAt(0).toUpperCase()+field[0].slice(1) } is Required`
	            : '';
    		}
    	});

		if( objectValuesEmpty(this.state.errors) ) {
			// Send form data
			this.props.verifyOTP(this.state);

			// disable submit button
			// this.setState({ isSubmitting : true });
	    }else{

	    	// Enable submit button
			this.setState({ isSubmitting : false });

	    	// Show message
	    	const theme = 'panel bordered rounded primary';
	    	const icon =`fa fa-times-circle`;
	    	const message ='Provide a valid OTP Pin';
  			flash(theme, icon, message, '#alert');
	    }
	}

	handleFieldChange = (event) => {
		event.preventDefault();
		let el = event.target

		// Fill form field
		const { name, value } = el;
		this.setState( { [name] : value } );

		// Move to the next field
		if(value != ''){
			if(el.nextElementSibling != undefined){
			 	el.nextElementSibling.focus();
			}else{
				el.previousElementSibling.focus();
			}
		}
	}

	handleDelete = (event) => {
		let key = event.which || event.keyCode || event.charCode;
		let el = event.target;
		// Validate form
		const { name, value } = el;

		// Move the cursor left on delete
		if(key == 8 || key == 46){
			this.setState( { [name] : '' } );
			if(el.previousElementSibling != undefined){
			 	el.previousElementSibling.focus();
			}else{
				el.nextElementSibling.focus();
			}
		}
	}

	render(){
		let errors = this.state.errors;

		return (
			<React.Fragment>
				<form id="customerVerifyForm" className="salade" onSubmit={ this.handleCustomerVerificationSubmit }>
					<div className="helper text margin top-two center">
						<span className="small text-tone-dark center"><b>Verify Your Account.</b> Enter the verification code sent to your email/phone number</span>
					</div>
					<div id="alert" className="hide">
						<span className="icon"><i className="" id="icon"></i></span>
						<span className="message" id="message"></span>
					</div>
					<div className="margin top-one">
					    	<div className="row grouped otp-pins middle">
					    		<input maxLength="1" id="otp1" name="otp1" value={ this.state.otp1 } onKeyDown={ this.handleDelete } onChange={ this.handleFieldChange } type="password" autoComplete="off" className="bordered rounded" autoFocus />
					    		<input maxLength="1" id="otp2" name="otp2" value={ this.state.otp2 } onKeyDown={ this.handleDelete } onChange={ this.handleFieldChange } type="password" autoComplete="off" className="bordered rounded" />
					    		<input maxLength="1" id="otp3" name="otp3" value={ this.state.otp3 } onKeyDown={ this.handleDelete } onChange={ this.handleFieldChange } type="password" autoComplete="off" className="bordered rounded" />
					    		<input maxLength="1" id="otp4" name="otp4" value={ this.state.otp4 } onKeyDown={ this.handleDelete } onChange={ this.handleFieldChange } type="password" autoComplete="off" className="bordered rounded" />
					    		<input maxLength="1" id="otp5" name="otp5" value={ this.state.otp5 } onKeyDown={ this.handleDelete } onChange={ this.handleFieldChange } type="password" autoComplete="off" className="bordered rounded" />
					    		<input maxLength="1" id="otp6" name="otp6" value={ this.state.otp6 } onKeyDown={ this.handleDelete } onChange={ this.handleFieldChange } type="password" autoComplete="off" className="bordered rounded" />
					    		<span className="text text-tone-dark"><i className="fa fa-lock fa-2x"></i></span>
					    	</div>
					    	{ errors.firstname &&
						    	<i className="text">
						    	<i className="fa fa-question-circle"></i> 
						    		<span className="text-tone-primary">{errors.firstname}</span>
						    	</i>
						    }

						    <div className="text text-tone-primary center margin top-two">
						    	<div>Didn't get the code, click <a href="" className="text uppercase"><b>here</b></a> to resend</div>
						    	<button className={ `rounded margin top-two ${ this.state.isSubmitting  ? 'bordered disabled' : 'fill-primary' }` } disabled={ this.state.isSubmitting }>Verify my account</button>
						    </div>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

export default CustomerVerificationForm;